import React, { Component } from 'react';
import basicMapDataToComponents from './basicMapDataToComponents';

import { graphql, Link } from 'gatsby';
import articleMapDataToComponents from '../components/article/articleMapDataToComponents';
import CardGrid from '../components/cards/cardGrid';
import ProjectCard from '../components/cards/projectCard';
import HeadTags from '../components/global/headTags';
import SimpleHeader from '../components/global/simpleHeader';
import SocialShare from '../components/global/socialShare';
import ArticleThemeContext from '../context/articleThemeContext';
import DefaultLayout from '../layouts/default-layout';
import { getLanguage } from '../utils/language';
import translations from '../utils/translations';
import StaticHero4x3 from '../components/global/staticHero4x3';
import { navigate } from 'gatsby-link';
import LogoSvg from '../components/global/logoSvg';
import * as style from './pressRelease.module.scss';
import PartnershipAlternate from '../components/latestStories/partnershipAlternate';
import ArticleList from '../components/article/articleList';

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      lang: 'en',
    };
    this.articleType = null;
    if (props.handlePageDataChanged && props.data.itemsJson.article_type) {
      props.handlePageDataChanged({ articleType: props.data.itemsJson.article_type });
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const newArticleType = nextProps.data.itemsJson.article_type;
    if (nextProps.handlePageDataChanged && newArticleType !== this.articleType) {
      this.articleType = newArticleType;
      nextProps.handlePageDataChanged({ articleType: newArticleType });
    }
  }
  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const lang = await getLanguage();
    this.setState({ lang });
    translations.setLanguageSource(lang, this.props.data.translations);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  render() {
    const {
      title,
      hide_date,
      created_date,
      archive,
      authors,
      meta_description,
      related_challenge,
      featured_image,
      featured_video,
      video,
      youtube_video,
      related_impacted_regions,
      make_this_article_faq,
      prevent_indexing
    } = this.props.data.itemsJson;
    const {
      popular_stories,
      press_release_info_component
    } = this.props.data;

    const paragraphs = this.props.data.itemsJson.paragraphs
      .filter(
        p => p.type !== 'article_list'
          && p.type !== 'bottom_callouts'
          && p.type !== 'partnership'
          && p.type !== 'project_list'
      );
    const bottomCallouts = this.props.data.itemsJson.paragraphs.filter(p => p.type === 'bottom_callouts');
    const stories = this.props.data.megaMenu.edges.find(e => e.node.lang === this.state.lang).node.latest_stories;
    const latestPrStories = this.props.data.megaMenu.edges.find(e => e.node.lang === this.state.lang).node.latest_pr_stories;
    let project_list = this.props.data.itemsJson.paragraphs.filter(p => p.type === 'project_list');
    let partnership = this.props.data.itemsJson.paragraphs.filter(p => p.type === 'partnership');
		let partnerships = [];
    
    if (partnership.length == 1) {
      partnership = partnership[0];
      partnerships.push(partnership);
    } else if (partnership.length > 1) {
	    partnerships = partnership;
    } else {
	    partnership = null;
    }

    if (project_list.length > 0) {
      project_list = project_list[0];
    } else {
      project_list = [];
    }

    return (
      <DefaultLayout {...this.props} excludeParentFunctions>
        <ArticleThemeContext.Provider value="mix">
          <div
            // style={this.props.transition && this.props.transition.style}
            // for some reason, fading out the whole article resulted in a long page getting
            // cut off. Some rendering issue. So instead we fade in a dark overlay on top of the content.
            className={`
              ${this.props.transition && this.props.transition.status === 'exiting'
                ? 'article-exiting'
                : ''}
            `}
          >
            <HeadTags
              title={title}
              description={meta_description}
              image={featured_image ? featured_image.image.sizes.hero_md : null}
              imageTwitter={
                featured_image ? featured_image.image.sizes.hero_sm : null
              }
              preventIndexing={prevent_indexing}
              location={this.props.location}
              isArticle
            />
            <div className={style.logo}>
              <Link to="/">
                <LogoSvg />
              </Link>
            </div>
            <div className={style.spacer} />
            
            <SimpleHeader
            	top={
	            	<div
						onClick={() => {
							navigate('/projects')
						}}
						className={`backLink`}
					>
						<a
							className={`icon-circle-orange fa-arrow-left backIcon`}
							rel="noopener"
						>
							<span>TOP</span>
						</a>
						<a className={`backText`}>
							PROJECTS
						</a>
					</div>
            	}
            	title={title}
            	description={meta_description}
            	createdDate={created_date}
            />
            
            {/* <BlurredImage src={featured_image} /> */}
            
            <StaticHero4x3
            	contain={true}
            	featuredImage={featured_image}
            	small={true}
            	creditAlignment={'left'}
            />
            
            <div className={`${style.paragraphs} ${style.dark} ${style.wrapDark}`}>
              <div className={`${style.container} ${style.clearBg}`}>
                <div className={style.threeColumn}>
                  <div className={style.columnOne}>
                  	
                  	{/*
                    {partnership &&
                      <PartnershipAlternate label={partnership.title} projectPage={true}>
                        <img src={partnership.path} className={style.imageMaxWidth100} />
                        <p>{partnership.text}</p>
                      </PartnershipAlternate>
                    }
                    */}
                    
                    {partnerships && partnerships.length > 0 && partnerships.map(function(item, i) {
											return (
												<div>
													<PartnershipAlternate label={item.title} projectPage={true}>
			                      <img src={item.path} className={style.imageMaxWidth100} />
			                      <p>{item.text}</p>
			                    </PartnershipAlternate>
												</div>
											);
										})}
                    
                  </div>
                  
                  <div className={`${style.paragraphs} ${style.centerButtons} ${style.columnTwo}`}>
                    {articleMapDataToComponents(
                      paragraphs,
                      popular_stories,
                      {
                        textClass: style.normalTextBlock,
                        theme: 'light',
                        simpleButtonOpenBlank: true
                      },
                      make_this_article_faq === '1'
                    )}
                    
					{/* */}
					{press_release_info_component.edges[0].node.press_release_info &&
						<div
							className={`${style.pressReleaseInfo} pressReleaseInfo`}
							pressReleaseInfo
							dangerouslySetInnerHTML={{ __html: press_release_info_component.edges[0].node.press_release_info }}
						>
							{/* {press_release_info_component.edges[0].node.press_release_info} */}
						</div>
					}
					{/* */}
                  </div>
                  <div
                  	className={style.columnThree}
                  >&nbsp;</div>
                </div>
              </div>
            </div>
            <div className={`${style.wrap}`}>
              {project_list.items && project_list.items.length > 0
                && <div className={style.wrap}>
                  <div className={`${style.container} ${style.cardGrid}`}>
                    <CardGrid className={style.maxWidth1200} cardComponent={ProjectCard} label={project_list.label} items={project_list.items} />
                  </div>
                </div>
              }
            </div>
            <SocialShare labelStyle={this.state.lang === 'es' ? { bottom: '120px' } : {}} title={title} />
            
            {/* */}
            {latestPrStories && latestPrStories.length > 0 &&
				<div
					id={'ProjectNews'}
					className={style.projectNews}
				>
					<h2 className={`${style.sectionHeading}`}>
						PROJECT NEWS
					</h2>
					<div
						className={`${style.sectionInset}`}
					>
						{latestPrStories && latestPrStories.length > 0 && latestPrStories.map(function(item, i) {
							var authors = (item.authors) ? item.authors.map((author) => author.name).join(' / ') : null;

							return (
								<div
									className={`${style.sectionItem}`}
								>
									<a href={item.path}>
										<div
											className={`${style.storyImg}`}
											style={{ backgroundImage: `url(${item.image.sizes.hero_sm})` }}
										></div>
									</a>
									<h3
										className={`${style.storyTitle}`}
									>
										<a href={item.path}>
											{item.title}&nbsp;<span className={style.angle}>&#8250;</span>
										</a>
									</h3>
									
									{/*
									{item.partner &&
										<div
											className={`${style.partner}`}
										>
											In Partnership with <span>{item.partner}</span>
										</div>
									}
									
									{authors &&
										<div
											className={`${style.authors}`}
										>
											By <span className={`${style.authorName}`}>{authors}</span>
										</div>
									}
									*/}
								</div>
							);
						})}
						
					</div>
				</div>
			}
            {/* */}
            {/*
            {latestPrStories
                && <div className={style.relatedReading}>
                  <ArticleList theme="off-white"
                    articles={latestPrStories}
                    label={translations.t("Related Reading")}
                  />
                </div>
              }
              */}
            {bottomCallouts.length > 0 &&
              basicMapDataToComponents(bottomCallouts, { reveal: true })
            }
          </div>
        </ArticleThemeContext.Provider>
      </DefaultLayout>
    );
  }
}

export default Article;

/*
authors {
  id
  name
}
partner	
*/

// eslint-disable-next-line
export const articlePageQuery = graphql`
  query PressRelease(
    $path: String!,
    $climateTrendId: String!,
    $popularStoriesId: String!,
    $isEs: Boolean!,
    $idRegex: String!,
    $blog: String!,
    $translations: String!) {
   itemsJson(path: { eq: $path }) {
      title
      hide_date
			created_date
      short_description
      meta_description
      article_type
      related_challenge {
        title
      }
      archive {
        title
        path
      }
      authors {
        id
        name
        description
        description_plain
        social_media_link {
          uri
          title
        }
        linkedin_link {
          uri
          title
        }
        bio_url {
          uri
          title
        }
        bio_image {
          url
          alt
        }
      }
      youtube_video {
        credit
        url
      }
      make_this_article_faq
      featured_image {
        image {
          sizes {
            hero_lg
            hero_md
            hero_sm
            original
          }
          credit_plain
          caption
        }
      }
      video {
        url
      }
      related_impacted_regions {
        content_type
        path
        title
        region_id
      }
      featured_video {
        id
        url
        caption
        image {
          sizes {
            original
            standard_md
            standard_sm
          }
        }
      }
      categories {
        name
        es_name
        link
      }
		prevent_indexing
      ...queryParagraphFields
    }
    climateTrends: itemsJson(jsonId: { eq: $climateTrendId }) {
      path
    }
    popular_stories: itemsJson(jsonId: { eq: $popularStoriesId }) {
      articles {
        id
        title
        path
        archive {
          title
          path
        }
        authors {
          name
        }
      }
    }

  ...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
  ...headerFieldsEs @include(if:$isEs)
  ...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
  newsletter_component_en: allItemsJson(filter: {jsonId: {eq: "en-11"}}) {
    edges {
      node {
        newsletter_intro
        newsletter_complete
        newsletter_action
      }
    }
  }
  newsletter_component_es: allItemsJson(filter: {jsonId: {eq: "es-11"}}) {
    edges {
      node {
        newsletter_intro
        newsletter_complete
        newsletter_action
      }
    }
  }
  press_release_info_component: allItemsJson(filter: {jsonId: {eq: "en-11"}}) {
    edges {
      node {
        press_release_info
      }
    }
  }
  megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			latest_pr_stories {
				id
				title
				path
				image {
					sizes {
						hero_lg
						hero_md
						hero_sm
						original
						near_square_md
					}
				}
				archive {
					id
					title
					path
				}
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
}
`;
