// extracted by mini-css-extract-plugin
export var spacer = "pressRelease-module--spacer--2XRJ2";
export var wrap = "pressRelease-module--wrap--8fG8D";
export var wrapDark = "pressRelease-module--wrapDark--A0K3b";
export var container = "pressRelease-module--container--POU39";
export var cardGrid = "pressRelease-module--cardGrid--ULqjq";
export var normalTextBlock = "pressRelease-module--normalTextBlock--6NWdJ";
export var paragraphs = "pressRelease-module--paragraphs--SlGQA";
export var articleFooter = "pressRelease-module--articleFooter--rCNhB";
export var inlineSocialShareMobile = "pressRelease-module--inlineSocialShareMobile--USfuy";
export var shareBlock = "pressRelease-module--shareBlock--TZi6C";
export var shareArrowIcon = "pressRelease-module--shareArrowIcon--PSID0";
export var shareText = "pressRelease-module--shareText--PcOQB";
export var threeColumn = "pressRelease-module--threeColumn--0X084";
export var columnOne = "pressRelease-module--columnOne--wSv8-";
export var columnTwo = "pressRelease-module--columnTwo--NNMRK";
export var columnThree = "pressRelease-module--columnThree--NuzN-";
export var relatedReading = "pressRelease-module--relatedReading--34jyC";
export var center = "pressRelease-module--center--g2O-x";
export var imageMaxWidth100 = "pressRelease-module--imageMaxWidth100--5ZjN2";
export var centerButtons = "pressRelease-module--centerButtons--XOA4s";
export var clearBg = "pressRelease-module--clearBg--evtBY";
export var maxWidth1200 = "pressRelease-module--maxWidth1200--TPmid";
export var logo = "pressRelease-module--logo--HUGKM";
export var pressReleaseInfo = "pressRelease-module--pressReleaseInfo--KsuPc";
export var sectionHeading = "pressRelease-module--sectionHeading--CsbbP";
export var projectNews = "pressRelease-module--projectNews--kQFZX";
export var sectionInset = "pressRelease-module--sectionInset--ME0ct";
export var sectionItem = "pressRelease-module--sectionItem--x0miT";
export var storyImg = "pressRelease-module--storyImg--ZhSdE";
export var storyTitle = "pressRelease-module--storyTitle--rhagg";
export var angle = "pressRelease-module--angle--Vp17C";
export var slideUp = "pressRelease-module--slideUp--oTsYz";
export var slideUpLarge = "pressRelease-module--slideUpLarge--JYX73";